import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,Commonh5,Commonpara} from '../Common/common.style';

export const FeaturesSection = styled.section`
    background-repeat:no-repeat;
    background-size:cover;
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;


export const HeadingLayout = styled.div`
    margin-bottom:40px;
    text-align:center;
`;

export const MainHeading = styled(SectionHeading)`
    
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const FeaturesSingle = styled.div`
    text-align:center;
    margin-bottom:30px;
`;

export const FeaturesImg = styled.img`
    width:auto;
    height:60px;
    margin-bottom:20px;
`;

export const Heading = styled(Commonh5)`
    line-height:1;

    @media ${device.tablet}{
        line-height:1;
    }
`;

export const FeaturesDesc = styled(Commonpara)`
    text-align:center;
`;
