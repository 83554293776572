import React,{Component} from "react";
import {BannerSection,
    BannerSliderWrapper,
    BannerSliderLayout,
    BannerImageHolder,
    BannerContents,
    BannerTextLayout,
    BannerHeading,
    BannerSubHeading,
    PrevImg,
    NextImg,
    NextPrev,
    ImgButton
} from './banner.style'
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Banner extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            fade:true,
            dots:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000
        };
        return (
            <BannerSection id="homeSection">
                <BannerSliderWrapper>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        this.props.BannerData.BannerSlides.map((item,idx)=>{
                            return<BannerSliderLayout>
                                <BannerImageHolder>
                                    <GatsImg 
                                        fluid={item.BannerImage.childImageSharp.fluid} 
                                        alt=""
                                        className="bannerImage"
                                    />
                                </BannerImageHolder>
                                <BannerContents>
                                    <BannerTextLayout>
                                        <BannerHeading>{item.BannerH3}</BannerHeading>
                                        <BannerSubHeading>{item.BannerH4}</BannerSubHeading>
                                    </BannerTextLayout>
                                </BannerContents>
                            </BannerSliderLayout>
                        })
                    }
                    </Slider>
                    <NextPrev>
                        <ImgButton onClick={this.previous} aria-label="Prev Button">
                            <PrevImg src={this.props.BannerData.PrevImg} alt=""/>
                        </ImgButton>
                        <ImgButton onClick={this.next} aria-label="Next Button">
                            <NextImg src={this.props.BannerData.NextImg} alt=""/>
                        </ImgButton>
                    </NextPrev>
                </BannerSliderWrapper>
            </BannerSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                realestatePage2Json{
                    Banner{
                        BannerSlides{
                            BannerImage{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            BannerH3
                            BannerH4
                        }
                        PrevImg
                        NextImg
                    }
                }
            }
        `}
        render={(data) => (
            <Banner BannerData={data.realestatePage2Json.Banner}/>
        )}
    />
    )