import styled from 'styled-components';
import {Commonh3,Commonh4,Commonpara} from '../Common/common.style';
import {device} from '../Common/device';
import {Row} from 'react-bootstrap';
import { TabList} from 'react-tabs';
import {ChevronLeft} from '@styled-icons/fa-solid/ChevronLeft';
import {ChevronRight} from '@styled-icons/fa-solid/ChevronRight';

export const SpecificationsSection = styled.section`
    background:#fafafa;

    @media ${device.laptop}{
        padding:100px 0px 90px;
    }

    @media ${device.tablet}{
        padding:80px 10px 70px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
    text-align:right;

    @media ${device.laptop}{
        text-align:center;
    }
`;

export const Heading = styled(Commonh3)`
    text-align:right;

    @media ${device.laptop}{
        text-align:center;
    }
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const CustomTabList = styled(TabList)`
    margin:0;
    text-align:right;

    @media ${device.laptop}{
        text-align:center;
        margin-bottom:20px;
    }

    li{
        color:#5f5f5f;
        font-size:16px;
        padding:6px 0px;

        @media ${device.laptop}{
            border:1px solid #eaeaea;
            border-radius:5px;
            padding:6px 10px;
            margin-left:3px;
            margin-right:3px;
            margin-bottom:10px;
        }
    }

    .react-tabs__tab--selected{
        color:#282828;
        font-size:18px;
        border:none;
        border-radius:5px;
        background:none;
        font-weight:500;

        @media ${device.laptop}{
            border:1px solid #eaeaea;
        }
    }
`;

export const SliderWrapper = styled.div`

`;

export const ImgButtonLeft = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:0;
    position:absolute;
    top:calc(50% - 15px);
    z-index:99;
    background:#f4ba10;
    padding: 10px 15px;
    left: 15px;

    :hover{
        background:#c39409;
    }

    :focus{
        outline:0;
    }
`;

export const LeftIcon = styled(ChevronLeft)`
    width:20px;
    height:20px;
    color:#fff;
`;

export const SliderLayout = styled.div`
    .propertyImg{
        height:600px;
        
        @media ${device.laptop}{
            margin-bottom:30px;
        }
    }
`;

export const ImgButtonRight = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:0;
    position:absolute;
    top:calc(50% - 15px);
    z-index:99;
    background:#f4ba10;
    padding: 10px 15px;
    right: 15px;

    :hover{
        background:#c39409;
    }

    :focus{
        outline:0;
    }
`;

export const RightIcon = styled(ChevronRight)`
    width:20px;
    height:20px;
    color:#fff;
`;

export const SpecificationsHeading = styled(Commonh4)`
    text-align:left;
`;

export const SpecificationsPara = styled(Commonpara)`

`;

export const SpecificationsOuterWrapper = styled.div`

`;

export const SpecificationsLayout = styled.div`

`;

export const SpecWrapper = styled.div`

`;

export const SpecLayout = styled.div`
    display:flex;
    align-items:center;
`;  

export const SpecIcon = styled.img`
    width:24px;
    flex-shrink:0;
`;

export const SpecText = styled(Commonpara)`
    margin-bottom:0px;
    margin-left:10px;
`;

export const SeparatorLine = styled.hr`
    background: #e1e1e1;
    margin: 10px 0px;
    border: none;
    height: 1px;
`;

