import styled from 'styled-components';
import {SectionHeading,Commonh6} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg1 from '../../../assets/realestate-2-images/amenity-1.jpg';
import BackgroundImg2 from '../../../assets/realestate-2-images/amenity-2.jpg';
import BackgroundImg3 from '../../../assets/realestate-2-images/amenity-3.jpg';
import BackgroundImg4 from '../../../assets/realestate-2-images/amenity-4.jpg';
import BackgroundImg5 from '../../../assets/realestate-2-images/amenity-5.jpg';
import BackgroundImg6 from '../../../assets/realestate-2-images/amenity-6.jpg';
import BackgroundImg7 from '../../../assets/realestate-2-images/amenity-7.jpg';
import BackgroundImg8 from '../../../assets/realestate-2-images/amenity-8.jpg';
import BackgroundImg9 from '../../../assets/realestate-2-images/amenity-9.jpg';
import BackgroundImg10 from '../../../assets/realestate-2-images/amenity-10.jpg';
import BackgroundImg11 from '../../../assets/realestate-2-images/amenity-11.jpg';
import BackgroundImg12 from '../../../assets/realestate-2-images/amenity-12.jpg';

export const AmenitiesSection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet}{
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:40px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const AmenitiesLayout = styled.div`

    ${props => props.position && props.position===1 &&`
    background-image:url(${BackgroundImg1});
    `}

    ${props => props.position && props.position===2 &&`
    background-image:url(${BackgroundImg2});
    `}

    ${props => props.position && props.position===3 &&`
    background-image:url(${BackgroundImg3});
    `}

    ${props => props.position && props.position===4 &&`
    background-image:url(${BackgroundImg4});
    `}

    ${props => props.position && props.position===5 &&`
    background-image:url(${BackgroundImg5});
    `}

    ${props => props.position && props.position===6 &&`
    background-image:url(${BackgroundImg6});
    `}

    ${props => props.position && props.position===7 &&`
    background-image:url(${BackgroundImg7});
    `}

    ${props => props.position && props.position===8 &&`
    background-image:url(${BackgroundImg8});
    `}

    ${props => props.position && props.position===9 &&`
    background-image:url(${BackgroundImg9});
    `}

    ${props => props.position && props.position===10 &&`
    background-image:url(${BackgroundImg10});
    `}

    ${props => props.position && props.position===11 &&`
    background-image:url(${BackgroundImg11});
    `}

    ${props => props.position && props.position===12 &&`
    background-image:url(${BackgroundImg12});
    `}

    margin-bottom:30px;
    border-radius:5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 140px;
    overflow: hidden;
`;

export const AmenitiesImage = styled.img`

`;

export const AmenitiesTextLayout = styled.div`
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)); 
    width: 100%;
    padding: 30px 10px 0px 10px;
`;

export const AmenitiesText = styled(Commonh6)`
    margin-bottom:10px;
    color:#fff;
    text-align:left;
`;


