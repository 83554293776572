import styled from 'styled-components';
import {SectionHeading,Commonh5} from '../Common/common.style';
import {device} from '../Common/device'
import {ChevronLeft} from '@styled-icons/fa-solid/ChevronLeft';
import {ChevronRight} from '@styled-icons/fa-solid/ChevronRight';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const OurAgentsSection = styled.section`
    padding:100px 0px 80px;
    background:#fafafa;
    
    @media ${device.tablet}{
        padding:80px 0px 60px;
        background:#fafafa;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:20px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const SliderOuterWrapper = styled.div`
    position:relative;
    margin-left:-15px;
    margin-right:-15px;
`;

export const ImgButtonLeft = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;
    position:absolute;
    top:calc(50% - 15px);
    z-index:99;
    background:#f4ba10;
    padding: 10px 15px;
    left:15px; //OurAgentsLayout -left margin is 15px

    @media ${device.tablet} {
        left: 10px; //OurAgentsLayout -left margin is 10px
    }

    :hover{
        background:#c39409;
    }

    :focus{
        outline:0;
    }
`;

export const LeftIcon = styled(ChevronLeft)`
    width:20px;
    height:20px;
    color:#fff;
`;

export const OurAgentsSliderCol = styled.div`
    outline:0;
`;

export const OurAgentsLayout = styled.div`
    box-shadow: 0px 0px 16px 0px #d0d0d0;
    background: #fff;
    border-radius: 10px;
    margin: 20px 15px;

    @media ${device.tablet} {
        margin: 20px 10px;
        box-shadow: 0px 0px 6px 0px #d0d0d0;
    }
`;

export const OurAgentsImgFigure = styled.figure`
    overflow: hidden;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom:0px;

    :hover .agentImg{
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    .agentImg{
        width:100%;
        height:320px;
        object-fit:cover;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;

export const OurAgentsDescLayout = styled.div`
    text-align:center;
    padding: 30px;
`;

export const OurAgentsName = styled(Commonh5)`
    line-height:1;
    margin-bottom:10px;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const OurAgentsTel = styled.a`
    text-align: center;
    margin-bottom:15px;
    line-height:1;
    color:#666666;
    display:block;

    :hover{
        color:#f4ba10;
        text-decoration:none;
    }
`;

export const OurAgentsSocial = styled.a`
    display: inline-block;
    :hover svg{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #f4ba10;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    background: #f4ba1033;
    transition:all .5s;
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #f4ba10;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    background: #f4ba1033;
    transition:all .5s;
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #f4ba10;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    background: #f4ba1033;
    transition:all .5s;
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #f4ba10;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    background: #f4ba1033;
    transition:all .5s;
`;

export const ImgButtonRight = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;
    position:absolute;
    top:calc(50% - 15px);
    z-index:99;
    background:#f4ba10;
    padding: 10px 15px;
    right: 15px; //OurAgentsLayout - Right margin is 15px

    @media ${device.tablet} {
        right: 10px; //OurAgentsLayout - Right margin is 10px
    }

    :hover{
        background:#c39409;
    }

    :focus{
        outline:0;
    }
    
`;

export const RightIcon = styled(ChevronRight)`
    width:20px;
    height:20px;
    color:#fff;
`;
