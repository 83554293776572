import React from 'react';
import {AboutSection,CustomRow,TextLayout,HeadingLayout,Heading,Border,Description1,
    SubHeading,List,ListItem,CheckCircleIcon,ListText,ImageHolder
} from './about.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage2Json{
                    About{
                       Heading
                       Border
                       Description1
                       SubHeading
                       ListText1
                       ListText2
                       ListText3
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
    const AboutData = JSONData.realestatePage2Json.About;

    return (
        <AboutSection id="aboutSection">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <HeadingLayout>
                                <Heading>
                                {AboutData.Heading} 
                                </Heading>
                                <Border src={AboutData.Border} alt=""/>
                            </HeadingLayout>
                            <Description1>
                            {AboutData.Description1} 
                            </Description1>
                            <SubHeading>{AboutData.SubHeading}</SubHeading>
                            <List>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <ListText>{AboutData.ListText1}</ListText>
                                </ListItem>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <ListText>{AboutData.ListText2}</ListText>
                                </ListItem>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <ListText>{AboutData.ListText3}</ListText>
                                </ListItem>
                            </List>
                        </TextLayout>
                    </Col>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg 
                                fluid={AboutData.Img.childImageSharp.fluid} 
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;