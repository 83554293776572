import React,{Component} from 'react';
import {SpecificationsSection,CustomRow,HeadingLayout,Heading,Border,CustomTabList,SliderWrapper,
    ImgButtonLeft,LeftIcon,
    SliderLayout,SpecificationsOuterWrapper,SpecificationsLayout,SpecLayout,SpecIcon,SpecText,
    SpecificationsHeading,SpecificationsPara,SpecWrapper,
    ImgButtonRight,RightIcon,SeparatorLine
} from './specifications.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GatsImg from "gatsby-image";

class SpecificationsPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            specificationsList: [],
            specificationsHeading: [],
            specificationsPara: [],
            oldSpec:true,
            newSpec:false
        }
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    doSpecification(item)
    {
        this.setState(
            {
                oldSpec:false,
                newSpec:true,
                specificationsHeading:item.SpecHeading,
                specificationsPara:item.SpecPara,
                specificationsList: item.SpecLayout
            }
        );
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        
        return(
            <SpecificationsSection id="specificationsSection">
                <Container>
                    <Tabs>
                        <CustomRow>
                            <Col lg={3}>
                                <HeadingLayout>
                                    <Heading>
                                        {this.props.SpecificationsData.Heading}
                                    </Heading>
                                    <Border src={this.props.SpecificationsData.Border} alt=""/>
                                </HeadingLayout>
                                <CustomTabList>
                                {
                                    this.props.SpecificationsData.TabList.map((item,idx) => {
                                        return <Tab onClick={this.doSpecification.bind(this,item)}>{item.Tab}</Tab>
                                    })
                                }
                                </CustomTabList>
                            </Col>
                            <Col lg={9}>
                            {
                                this.props.SpecificationsData.TabList.map((itemTab,idxTab) => {
                                return  <TabPanel>
                                            <CustomRow>
                                                <Col lg={8}>
                                                    <SliderWrapper>
                                                        <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                                            <LeftIcon />
                                                        </ImgButtonLeft>
                                                        <Slider ref={c => (this.slider = c)} {...settings}>
                                                        {
                                                            itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                                            return <SliderLayout>
                                                                        <GatsImg 
                                                                            fluid={itemPanel.Img.childImageSharp.fluid} 
                                                                            alt=""
                                                                            className="propertyImg"
                                                                        />
                                                                    </SliderLayout>
                                                                })
                                                        }
                                                        </Slider>
                                                        <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                                            <RightIcon />
                                                        </ImgButtonRight>
                                                    </SliderWrapper>
                                                </Col>
                                                <Col lg={4}>
                                                    <SpecificationsOuterWrapper>
                                                        <SpecificationsLayout>
                                                            
                                                            <SpecificationsHeading>
                                                            {itemTab.SpecHeading} 
                                                            </SpecificationsHeading>
                                                            <SpecificationsPara>
                                                            {itemTab.SpecPara} 
                                                            </SpecificationsPara>
                                                            {
                                                                itemTab.SpecLayout.map((specItem, specIdx)=>{
                                                                    return <SpecWrapper>
                                                                        <SpecLayout>
                                                                            <SpecIcon src={specItem.SpecIcon} alt=""/>
                                                                            <SpecText>
                                                                            {specItem.SpecText} 
                                                                            </SpecText>
                                                                        </SpecLayout>
                                                                        {
                                                                            specIdx !== 4 &&
                                                                            <SeparatorLine />
                                                                        }
                                                                        
                                                                    </SpecWrapper>
                                                                })
                                                            }
                                                        </SpecificationsLayout>
                                                    </SpecificationsOuterWrapper>
                                                </Col>
                                            </CustomRow>
                                    </TabPanel>
                                })
                            }
                            </Col>
                        </CustomRow>
                    </Tabs>
                </Container>
            </SpecificationsSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`
        query{
            realestatePage2Json {
                Specifications {
                    Heading
                    Border
                    TabList{
                        Tab
                        SpecHeading
                        SpecPara
                        TabPanel{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        SpecLayout{
                            SpecIcon
                            SpecText
                        }
                    }
                }
            }
        }
        
    `}

    render={(data) => (
        <SpecificationsPage SpecificationsData={data.realestatePage2Json.Specifications}/>
    )}
/>
)