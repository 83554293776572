import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #f4ba10;
    color: #282828;
    padding: 10px 40px 12px;
    text-decoration:none;
    border-radius: 5px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#c39409;
    }

    @media ${device.mobileXL} {
        padding: 10px 30px;
    }
`;

export const Commonh1 = styled.h1`
    font-size:70px;
    line-height:80px;
    text-align:center;
    margin-bottom:20px;
    color: #282828;

    @media ${device.laptop} {
        font-size: 54px;
        line-height: 60px;
    }

    @media ${device.tablet} {
        font-size: 46px;
        line-height: 52px;
    }
    @media ${device.mobileXL} {
        font-size:36px;
        line-height:42px;
    }
`;

export const Commonh2 = styled.h2`
    font-size: 50px;
    line-height:56px;
    text-align:center;
    margin-bottom: 20px;
    color: #282828;

    @media ${device.tablet} {
        font-size:46px;
        line-height:52px;
    }
`;

export const Commonh3 = styled.h3`
    font-size: 38px;
    line-height:44px;
    text-align:center;
    margin-bottom: 20px;
    color: #282828;

    @media ${device.laptopM} {
        font-size:34px;
        line-height:40px;
    }

    @media ${device.mobileXL} {
        font-size:30px;
        line-height:36px;
    }
`;

export const Commonh4 = styled.h4`
    font-size:32px;
    line-height:38px;
    text-align:center;
    margin-bottom:20px;
    color: #282828;

    @media ${device.tablet} {
        font-size:28px;
        line-height:32px;
    }
`;

export const Commonh5 = styled.h5`
    font-size:24px;
    line-height:30px;
    text-align:center;
    margin-bottom:20px;
    color: #282828;

    @media ${device.tablet} {
        font-size:22px;
        line-height:28px;
    }
`;

export const Commonh6 = styled.h6`
    font-size:18px;
    line-height:24px;
    text-align:center;
    margin-bottom:20px;
    color: #282828;

    @media ${device.mobileXL} {
        font-size:17px;
        line-height:23px;
    }
`;

export const Commonpara = styled.p`
    color:#666666;
    margin-bottom:20px;
    font-size:15px;
    line-height:1.5;
`;

export const SectionHeading = styled(Commonh3)`
    text-align:center;
    line-height:1;

    @media ${device.laptopM} {
        line-height:1;
    }

    @media ${device.mobileXL} {
        line-height:1;
    }
`;
