import React from 'react';
import {ConsultationSection,CustomRow,ConsultationLayout,ConsultationHeading,ConsultationText,
    BtnLayout,CallNowBtn
} from './consultation.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Consultation = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage2Json{
                    Consultation{
                        ConsultationHeading
                        ConsultationSubHeading
                        ConsultationBtn
                    }
                }
            }
        `);
        const ConsultationData = JSONData.realestatePage2Json.Consultation;

        return (
        <ConsultationSection id="consultationSection">
            <Container>
                <CustomRow>
                    <Col md={8}>
                        <ConsultationLayout>
                            <ConsultationHeading>
                            {ConsultationData.ConsultationHeading} 
                            </ConsultationHeading>
                            <ConsultationText>
                            {ConsultationData.ConsultationSubHeading} 
                            </ConsultationText>
                        </ConsultationLayout>
                    </Col>
                    <Col md={4}>
                        <BtnLayout>
                            <CallNowBtn>
                            {ConsultationData.ConsultationBtn} 
                            </CallNowBtn>
                        </BtnLayout>
                    </Col>
                </CustomRow>
            </Container>
        </ConsultationSection>
    );
}
export default Consultation;