import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonpara,Commonh5,Commonh3} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {RightArrow} from '@styled-icons/boxicons-regular/RightArrow';

export const AboutSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:40px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const Heading = styled(Commonh3)`
    text-align:left;
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const Description1 = styled(Commonpara)`

`;

export const SubHeading = styled(Commonh5)`
    text-align:left;
    line-height:1;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const List = styled.ul`
    list-style:none;
    margin:0;
`;

export const ListItem = styled.li`
    display:flex;
    margin-bottom: 10px;
    align-items:center;

    :last-child{
        margin-bottom:0px;
    }
`;

export const CheckCircleIcon = styled(RightArrow)`
    color:#f4ba10;
    height:15px;
    width:15px;
    margin-top: 2px;
    flex-shrink:0;
`;

export const ListText = styled(Commonpara)`
    line-height:1;
    margin-bottom:0;
    margin-left:10px;
`;

export const ImageHolder = styled.div`
    padding-right:50px;

    @media ${device.laptop} {
        padding-right:0px;
    }
`;


