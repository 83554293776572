import React,{Component} from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TestimonialsSection,HeadingLayout,Heading,Border,SliderOuterWrapper,CardOuterWrapper,
    Card,TopCardLayout,CardProfileLayout,BottomCardLayout,ProfilePicLayout,ProfileNameLayout,ProfileName,Designation,
    QuotesHolder,QuotesIcon,Testimonial,PrevImg,NextImg,NextPrev,ImgButton} from './testimonials.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding:"0px",
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };
        
        return(
            <TestimonialsSection id="testimonialsSection">
                <Container>
                    <HeadingLayout>
                        <Heading>
                            {this.props.TestimonialsData.Heading} 
                        </Heading>
                        <Border src={this.props.TestimonialsData.Border} alt=""/>
                        </HeadingLayout>

                        <SliderOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.TestimonialsData.CardProfileLayout.map((item,idx) => {
                                    return <CardOuterWrapper>
                                        <Card>
                                            <TopCardLayout>
                                                <QuotesHolder>
                                                    <QuotesIcon/>
                                                </QuotesHolder>
                                                <Testimonial>
                                                    {item.Testimonial}
                                                </Testimonial>
                                            </TopCardLayout>

                                            <BottomCardLayout>
                                                <CardProfileLayout>
                                                    <ProfilePicLayout>
                                                        <GatsImg 
                                                            fluid={item.ProfilePic.childImageSharp.fluid} 
                                                            className="ProfilePic"
                                                            alt=""
                                                        />
                                                    </ProfilePicLayout>
                                                    <ProfileNameLayout>
                                                        <ProfileName>
                                                            {item.ProfileName}
                                                        </ProfileName>
                                                        <Designation>
                                                            {item.Designation}
                                                        </Designation>
                                                    </ProfileNameLayout>
                                                </CardProfileLayout>
                                            </BottomCardLayout>
                                        </Card>
                                    </CardOuterWrapper>
                                })
                            }
                            </Slider>
                        </SliderOuterWrapper>

                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.TestimonialsData.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.TestimonialsData.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>

                </Container>
            </TestimonialsSection>
        );
    }
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                    realestatePage2Json{
                        Testimonials{
                            Heading
                            Border
                            CardProfileLayout{
                                ProfilePic{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                ProfileName
                                Designation
                                Testimonial
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Testimonials TestimonialsData={data.realestatePage2Json.Testimonials}/>
        )}
    />
  )