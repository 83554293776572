import styled from 'styled-components';
import {Commonpara,Commonh2,Commonbtn} from '../Common/common.style';
import BackgroundImg from '../../../assets/realestate-2-images/contact-banner-parallax.jpg'
import {device} from '../Common/device';
import {Row} from 'react-bootstrap';

export const ConsultationSection = styled.section`
    background-image: url(${BackgroundImg});
    padding:100px 0px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;

    @media ${device.tablet}{
        padding:80px 10px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const ConsultationLayout = styled.div`

`;

export const ConsultationText = styled(Commonpara)`
    color:#fff;
    text-align:left;
    margin-bottom:0px;

    @media ${device.tablet}{
        margin-bottom:20px;
        text-align:center;
    }
`;

export const ConsultationHeading = styled(Commonh2)`
    color:#fff;
    text-align:left;

    @media ${device.tablet}{
        text-align:center;
    }
`;

export const BtnLayout = styled.div`
    text-align:right;

    @media ${device.tablet}{
        text-align:center;
    }
`;

export const CallNowBtn = styled(Commonbtn)`

`;
